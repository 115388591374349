<template>
  <section>
    <validation-observer ref="faqForm" #default="{invalid}">
      <b-form ref="form" @submit.prevent="onCreateOrUpdateFaq">
        <b-card
          class="blog-edit-wrapper"
        >
          <b-row>
            <b-col md="12">
              <b-form-group
                label="Question"
                label-for="blog-title"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Question"
                  rules="required"
                >
                  <b-form-input
                    id="question"
                    v-model="faqData.title"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            
            <b-col cols="12">
              <b-form-group
                label="Answer"
                label-for="faq-content"
                class="mb-2"
              >
              <validation-provider
                #default="{ errors }"
                name="Answer"
                rules="required"
              >
                <quill-editor
                  id="answer"
                  v-model="faqData.content"
                  :options="snowOption"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ !faqData.content && faqData.title? 'Please enter blog content' : '' }}</small>
              </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group
                label="Is Active"
                label-for="is_active"
                class="mb-2"
              >
                <b-form-checkbox
                    v-model="faqData.is_active"
                    name="is_active"
                    :disabled="disableForm"
                >
                  Show this FAQ to clients ?
                </b-form-checkbox>
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              class="mt-5"
            >
              <b-button
                class="mb-5"
                style="width: 150px"
                variant="primary"
                block
                type="submit"
                :disabled="invalid"
              >
                {{ isUpdate ? 'Update FAQ' : 'Create FAQ' }}
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-form>
    </validation-observer>
  </section>
</template>

<script>
import {
  BCard, 
  BMedia, 
  BAvatar, 
  BCardText, 
  BMediaAside, 
  BMediaBody, 
  BForm, 
  BRow, 
  BCol, 
  BFormGroup, 
  BFormInput, 
  BImg, 
  BFormFile, 
  BLink, 
  BButton,
  BFormCheckbox
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import { quillEditor } from 'vue-quill-editor'
import Ripple from 'vue-ripple-directive'

import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import { cloneDeep, get } from "lodash";

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const rawFaqData = {
  title: "",
  content: "",
  is_active: true
}
export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BMediaAside,
    BMediaBody,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormCheckbox,
    vSelect,
    quillEditor,

    // validations
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    updatingFaq: {
      type: [Object, null, undefined],
      default: null,
      required: false
    },
  },
  data() {
    return {
      loading: false,
      faqData: cloneDeep(rawFaqData),
      errorMessage: "",
      snowOption: {
        theme: 'snow',
      },

      required
    }
  },
  computed: {
    isUpdate(){
      return !!this.updatingFaq
    },
    disableForm(){
      if (this.isUpdate){
        return !this.can("faqs:CanUpdate")
      }
      return !this.can("faqs:CanAddNew")
    }
  },
  watch: {
    updatingFaq: {
      handler(update){
        if (update){
          this.faqData = cloneDeep(update)
        }
      }, 
      deep: true, 
      immediate: true
    }
  },
  created() {},
  methods: {
    async onCreateOrUpdateFaq(){
      try {
        this.loading = true;
        
        const success = await this.$refs.faqForm.validate();
        if (!success){
          return;
        }

        const formData = new FormData();

        formData.append("title", this.faqData.title);
        formData.append("content", this.faqData.content);
        formData.append("is_active", this.faqData.is_active);

        if (this.isUpdate){
          this.$emit("update", formData)
        } else {
          this.$emit("create", formData)
        }
      } catch (error){
        const error_message = get(error, "response.data.message") || error.message

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false
      }
    },
    resetForm(){
      Object.keys(cloneDeep(this.faqData)).forEach(key => {
        const resetValue = get(rawFaqData, key);
        this.faqData[key] = resetValue
        this.$set(this.faqData, resetValue);
      });
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/pages/page-blog.scss';
</style>
